import { IRecordType } from './RecordType';
import { IRecordTypeLayout } from './RecordTypeLayout';

export interface IRecordTypePage {
  id: string;
  recordTypeId: IRecordType['id'];
  displayName: string;
  name: string;
  pageType: PageType;
  content: Record<string, string> | { layout: IRecordTypeLayout };

  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export enum PageType {
  DETAILS = 'DETAILS',
  CONNECTIONS = 'CONNECTIONS',
  CAMPAIGN_CONTENT = 'CAMPAIGN_CONTENT',
}

export type RecordTypePageDTO = Pick<IRecordTypePage, 'displayName' | 'recordTypeId' | 'content'> &
  Partial<Pick<IRecordTypePage, 'id'>>;
